<template>
<v-card elevation="4" class="date-time-picker" width="400" height="300">
  <v-card-text>
    <v-tabs v-model="tab">
      <v-tab>
        <div>起止时间</div>
      </v-tab>
      <v-tab>
        <div>循环时间</div>
      </v-tab>
      <v-tab>
        <div>长期</div>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="pa-5 ml-12">
        <!-- date range -->
        <date-range-picker 
          ref="picker" 
          opens="center"            
          :locale-data="{ 
            firstDay: 1,
            format: 'yyyy-mm-dd',
            applyLabel: '确定',
            cancelLabel: '取消',
            daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
            monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
          }" 
          minDate="2018-01-01"
          maxDate="2050-12-31" 
          :dateRange="{startDate: task.task_start_date, endDate: task.task_end_date}" 
          :ranges="false" 
          @update="datePicker"
          :append-to-body="true"
          :time-picker="true"
          :time-picker24-hour="true"
          :date-format="dateFormat"
          >
          <template v-slot:input="picker">
            <div class="text-subtitle-1">开始时间</div>
            <div class="text-subtitle-2">{{ picker.startDate | getFormatDate }}</div>
            <div class="text-subtitle-1 mt-4">结束时间</div>
            <div class="text-subtitle-2">{{ picker.endDate | getFormatDate }}</div>             
          </template>
        </date-range-picker>
      </v-tab-item>
      <v-tab-item class="pa-5 ml-12">
        <v-container fluid>
          <v-row class="align-center my-5">
            <div class="grey--text">起止日期</div>
            <date-range-picker 
              ref="picker" 
              opens="center"            
              :locale-data="{ 
                firstDay: 1,
                format: 'yyyy-mm-dd',
                applyLabel: '确定',
                cancelLabel: '取消',
                daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
              }" 
              minDate="2018-01-01"
              maxDate="2050-12-31" 
              :dateRange="{startDate: task.task_start_date, endDate: task.task_end_date}" 
              :ranges="false" 
              @update="datePicker"
              :append-to-body="true"
              :time-picker="true"
              :time-picker24-hour="true"
              >
              <template v-slot:input="picker">
                {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}            
              </template>              
            </date-range-picker>
          </v-row>
          <v-row>
            <v-menu v-model="cronMenu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn tile v-bind="attrs" tag="div" elevation="0" width="180" v-on="on" height="40">进展反馈：<span style="width:100px;">{{ cronText }}</span></v-btn>
              </template>
              <SetTaskCron @set="setCron" @close="cronMenu=false" />
            </v-menu>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item class="py-12 pt-4 ma-4">
        <date-picker v-model="task.task_start_date"></date-picker> - <span>长期</span>
        <v-checkbox v-model="longTerm" label="长期" value="null" hide-details></v-checkbox>
      </v-tab-item>
    </v-tabs-items>
  </v-card-text>
  <v-card-actions class="justify-end px-6 mt-0 pt-0">
    <v-btn @click="cancel" small>取消</v-btn>
    <v-btn @click="ok" small color="primary">确定</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'DateTimePicker',
  components: {
    DateRangePicker,
    SetTaskCron: () => import('@/components/common/SetTaskCron.vue'),
    DatePicker
  },
  props: ['task'],
  data() {
    return {
      tab: null,
      cronMenu: false,
      cronText: '',
      longTerm: ''
    }
  },
  watch: {
    tab: function(val) {
      if( val==2 ) {
        this.longTerm = 'null';
      } else {
        this.longTerm = '';
      }
    },
  },
  methods: {
    datePicker(param) {
      let start = param.startDate;
      let end = param.endDate;
      // start = new Date(start);
      // end = new Date(end);
      // start = `${start.getFullYear()}-${start.getMonth()+1}-${start.getDate()} ${start.getHours()}:${start.getMinutes()}`
      // end = `${end.getFullYear()}-${end.getMonth()+1}-${end.getDate()} ${end.getHours()}:${end.getMinutes()}`
      this.task.task_start_date = start
      this.task.task_end_date = end
    },
    dateLongPicker(param) {
      let start = param.startDate;
      this.task.task_start_date = start;
      this.task.task_end_date = 'null';
    },
    setCron(e) {
      this.task.task_feedback_time = e.value;
      this.cronText = e.text;
      this.cronMenu = false;
    },
    cancel() {
      this.$emit('cancel');
    },
    ok () {
      this.$emit('ok', this.longTerm);
    },
     dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() < new Date()
        }
        return classes
      }
  },
  filters: {
    getFormatDate(date) {
      let day = new Date(date);
      return `${day.getFullYear()}/${day.getMonth()+1}/${day.getDate()} ${day.getHours()}:${day.getMinutes()}`;
    }
  }
  
}
</script>

<style>
.date-time-picker {
  position: fixed;
  z-index: 1000;
}
.v-label {
  font-size: 14px;
}
</style>
